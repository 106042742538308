import React, { useContext, useEffect, useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
//import { useAuth } from "../../hooks/useAuth"
//import Loader from "components/Loaders/Loader"
import { Eye, EyeOff, Loader } from "lucide-react"

import { AuthContext } from "contexts/AuthContext"

export default function Login() {
  const { signIn } = useContext(AuthContext)

  const location = useLocation()
  const navigate = useNavigate()

  const [processing, setProcessing] = useState(false)

  const [errorMessages, setErrorMessages] = useState({})

  const [showPassword, setShowPassword] = useState(false)

  const [showAlert, setShowAlert] = useState(true)

  useEffect(() => {
    if (errorMessages && Object.keys(errorMessages).length > 0) {
      const timer = setTimeout(() => {
        setShowAlert(false)
      }, 5000) // Hide after 5 seconds
      return () => clearTimeout(timer)
    }
  }, [errorMessages])
  const closeAlert = () => {
    setShowAlert(false)
  }

  const handleSubmit = async (event) => {
    try {
      event.preventDefault()
      setProcessing(true)

      const formData = new FormData(event.currentTarget)
      const payload = {
        email: formData.get("email"),
        password: formData.get("password"),
      }

      const response = await signIn(payload)
      const { success } = response

      const session = JSON.parse(localStorage.getItem("talenty-user"))

      // Get the original route from the location state
      //const from = location.state?.from?.pathname || '/admin/jobboards';
      const from = location.state?.path || "/admin/jobboards"

      if (success && session) {
        navigate(from, { replace: true })
      }
    } catch (error) {
      setProcessing(false)
      setErrorMessages(error.errors)
      setShowAlert(true)
    }
  }

  return (
    <>
      <div className="min-h-screen bg-gradient-to-br from-white via-white to-purple-400">
        <div className="flex min-h-screen flex-col justify-center px-4 py-12 sm:px-6 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <img className="mx-auto h-20 w-auto" src={require("assets/img/talenty_logo_200x110.png")} alt="Talenty" />
          </div>

          <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div className="bg-white py-8 px-4 shadow-2xl sm:rounded-2xl sm:px-10 border border-gray-300 relative overflow-hidden">
              <div className="absolute top-0 left-0 right-0 h-1.5 bg-gradient-to-r from-indigo-600 via-purple-600 to-pink-600 animate-gradient" />
              <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <h2 className="text-center text-3xl font-semibold tracking-tight text-gray-900">Iniciar sesión</h2>
                <p className="mt-3 text-center text-sm text-gray-600">Ingresa tus credenciales para continuar</p>
              </div>

              <form onSubmit={handleSubmit} className="mt-8 space-y-6">
                <div className="relative w-full mb-3">
                  <label className="block text-sm font-medium text-gray-700" htmlFor="email">
                    Email
                  </label>
                  <div className="mt-1.5 relative">
                    <input
                      id="email"
                      type="email"
                      name="email"
                      placeholder="nombre@empresa.com"
                      className="block w-full appearance-none rounded-xl border-2 border-gray-200 bg-white px-4 py-3.5 placeholder-gray-400 shadow-sm transition duration-200 focus:border-indigo-500 focus:outline-none focus:ring-4 focus:ring-indigo-500/20 hover:border-gray-300"
                      required
                    />
                  </div>
                </div>

                <div className="relative w-full mb-3">
                  <label className="block text-sm font-medium text-gray-700" htmlFor="password">
                    Contraseña
                  </label>
                  <div className="mt-1.5 relative">
                    <input
                      id="password"
                      type={showPassword ? "text" : "password"}
                      name="password"
                      placeholder="••••••••"
                      className="block w-full appearance-none rounded-xl border-2 border-gray-200 bg-white px-4 py-3.5 placeholder-gray-400 shadow-sm transition duration-200 focus:border-indigo-500 focus:outline-none focus:ring-4 focus:ring-indigo-500/20 hover:border-gray-300"
                      required
                    />
                    <button
                      type="button"
                      onClick={() => setShowPassword(!showPassword)}
                      className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400 hover:text-gray-600"
                    >
                      {showPassword ? <EyeOff className="h-5 w-5" /> : <Eye className="h-5 w-5" />}
                    </button>
                  </div>
                </div>

                {showAlert && Object.keys(errorMessages).length > 0 && (
                  <div className="rounded-xl bg-red-100 p-4 border border-red-200">
                    <div className="flex items-center justify-between">
                      <div className="flex items-center">
                        <div className="flex-shrink-0">
                          <button onClick={closeAlert} className="text-red-500 hover:text-red-700">
                            <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                              <path
                                fillRule="evenodd"
                                d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </button>
                        </div>
                        <div className="ml-3">
                          {" "}
                          {Object.keys(errorMessages).map((key) => (
                            <p className="text-sm text-red-700 font-medium" key={key}>
                              {" "}
                              {errorMessages[key].join(", ")}{" "}
                            </p>
                          ))}{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="flex items-center justify-end">
                  <Link
                    to="/forgot-password"
                    className="text-sm font-medium text-indigo-600 hover:text-indigo-500 hover:underline"
                  >
                    ¿Olvidaste tu contraseña?
                  </Link>
                </div>
                <div className="text-center mt-6">
                  <button
                    type="submit"
                    className="flex w-full justify-center items-center rounded-lg bg-indigo-600 px-4 py-4 text-base font-semibold text-white shadow-md transition duration-200 hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-70 disabled:cursor-not-allowed hover:shadow-lg"
                    disabled={processing}
                  >
                    {processing ? (
                      <>
                        <Loader className="mr-2 h-4 w-4 animate-spin" />
                        <span>Procesando...</span>
                      </>
                    ) : (
                      <span className="group-hover:scale-105 transition-transform duration-200">Iniciar sesión</span>
                    )}
                  </button>
                </div>
              </form>

              <div className="mt-8">
                <div className="relative">
                  <div className="absolute inset-0 flex items-center">
                    <div className="w-full border-t border-gray-200" />
                  </div>
                  <div className="relative flex justify-center text-sm">
                    <span className="bg-white px-4 text-gray-500">¿No tienes cuenta?</span>
                  </div>
                </div>

                <div className="mt-6 text-center">
                  <Link
                    to="/auth/register"
                    className="text-sm font-medium text-indigo-600 hover:text-indigo-500 hover:underline"
                  >
                    Crear nueva cuenta
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
