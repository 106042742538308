import React, { createContext, useEffect } from "react"
import { useAuth } from "../hooks/useAuth"
import { useNavigate } from "react-router-dom"

const AuthContext = createContext({
  isAuthenticated: false,
  user: null,
  setIsAuthenticated: async () => {},
  setUser: async () => {},
  signIn: async () => {},
  signOut: async () => {},
  signUp: async () => {},
  storedUser: null,
  storedToken: null,
})

const AuthProvider = ({ children }) => {
  const navigate = useNavigate()
  const { signIn, signUp, signOut, isAuthenticated, user, setIsAuthenticated, setUser, storedUser, storedToken } =
    useAuth()

  useEffect(() => {
    if (storedToken) {
      setIsAuthenticated(true)
      setUser(storedUser)
    }
  }, [storedToken, storedUser, navigate])

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user,
        setIsAuthenticated,
        setUser,
        signIn,
        signOut,
        signUp,
        storedUser,
        storedToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export { AuthContext, AuthProvider }
