import { apitalenty } from "../../hooks/useAPIaxios"

export default async function request(method, url, payload = null, config = {}) {
  try {
    /**
     * AxiosResponse response
     * data is out of scope in the destructuring
     * so, I can not use data directly
     */
    const instance = apitalenty()

    const response = await instance.request({
      method,
      url,
      data: payload,
      ...config,
    })

    const {
      data: { success, message, data },
      status,
      statusText,
    } = response

    return {
      success,
      message,
      data,
      status,
      statusText,
    }
  } catch (error) {
    /**
     * AxiosError error
     * response and data is out of scope in the destructuring
     * so, I can not use response or data directly
     */
    const {
      response: {
        data: { success, message, errors },
        status,
        statusText,
      },
    } = error

    throw {
      success: success || false,
      message,
      errors,
      status,
      statusText,
    }
  }
}
