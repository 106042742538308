import React from "react"
import { Link } from "react-router-dom"

export default function EmailSent() {
  return (
    <>
      <div className="min-h-screen bg-gradient-to-br from-white via-white to-purple-400">
        <div className="flex min-h-screen flex-col justify-center px-4 py-12 sm:px-6 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <img
              className="mx-auto h-20 w-auto"
              src={require("assets/img/talenty_logo_200x110.png")}
              alt="Talenty"
            />
          </div>

          <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div
              className="bg-white py-8 px-4 shadow-2xl sm:rounded-2xl sm:px-10 border border-gray-300 relative overflow-hidden">
              {/* Animated gradient bar */}
              <div
                className="absolute top-0 left-0 right-0 h-1.5 bg-gradient-to-r from-indigo-600 via-purple-600 to-pink-600 animate-gradient"/>

              <div className="flex justify-center w-full">
                <div className="w-full max-w-sm space-y-6">
                  {/* Welcome message */}
                  <div className="text-center space-y-4">
                    <div className="bg-purple-50 p-6 rounded-lg border border-purple-100">
                      <h2 className="text-xl font-semibold text-purple-900 mb-2">
                        ¡Bienvenido a Talenty!
                      </h2>
                      <p className="text-purple-700">
                        Para comenzar, te hemos enviado un correo de confirmación.
                      </p>
                    </div>
                  </div>

                  {/* Login button */}
                  <Link
                    to="/auth/login"
                    className="w-full flex justify-center py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gradient-to-r from-purple-600 to-indigo-600 hover:from-purple-700 hover:to-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 transition-all duration-200 transform hover:scale-105"
                  >
                    Iniciar Sesión
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
