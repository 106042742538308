import React, { useEffect, useState } from "react"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import { ArrowLeft, CheckCircle, Eye, EyeOff, KeyRound, Loader } from "lucide-react"
import { resetPassword } from "../modules/Auth/AuthServices"

export const ResetPassword = () => {
  const [searchParams] = useSearchParams()
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [showPassword, setShowPassword] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState("")
  const [resetSuccess, setResetSuccess] = useState(false)
  const [counter, setCounter] = useState(null)

  const token = searchParams.get("token")
  const email = searchParams.get("email")
  const navigate = useNavigate()

  useEffect(() => {
    const timer = counter && setInterval(() => setCounter((prev) => prev - 1), 1000)

    if (counter === 0) {
      navigate("/auth/login", { replace: true })
    }

    return () => clearInterval(timer)
  }, [counter, navigate])

  const handleSubmit = async (e) => {
    try {
      e.preventDefault()
      setProcessing(true)

      if (password !== confirmPassword) {
        setProcessing(false)
        setError("Las contraseñas no coinciden")
        return
      }

      const payload = {
        email,
        password,
        password_confirmation: confirmPassword,
        token,
      }

      const response = await resetPassword(payload)
      const { success } = response

      if (success) {
        setResetSuccess(true)
        setCounter(5)
      }
    } catch (err) {
      setError("Error al restablecer la contraseña. Por favor, intenta nuevamente.")
    } finally {
      setProcessing(false)
    }
  }

  if (resetSuccess) {
    return (
      <>
        <div className="min-h-screen bg-gradient-to-br from-white via-white to-purple-400">
          <div className="flex min-h-screen flex-col justify-center px-4 py-12 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
              <div className="bg-white py-8 px-4 shadow-2xl sm:rounded-2xl sm:px-10 border border-gray-300 relative overflow-hidden">
                <div className="absolute top-0 left-0 right-0 h-1.5 bg-gradient-to-r from-indigo-600 via-purple-600 to-pink-600" />
                <div className="text-center">
                  <CheckCircle className="mx-auto h-12 w-12 text-green-500" />
                  <h3 className="mt-4 text-xl font-medium text-gray-900">¡Contraseña actualizada!</h3>
                  <p className="mt-2 text-sm text-gray-600">
                    Tu contraseña ha sido actualizada exitosamente. Serás redirigido al inicio de sesión en {counter}
                  </p>
                </div>
                <div className="mt-6 text-center">
                  <Link
                    to="/auth/login"
                    className="inline-flex items-center text-sm font-medium text-indigo-600 hover:text-indigo-500"
                  >
                    <ArrowLeft className="mr-2 h-4 w-4" />
                    Volver al inicio de sesión
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-white via-white to-purple-400">
      <div className="flex min-h-screen flex-col justify-center px-4 py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <div className="flex justify-center">
            <KeyRound className="h-12 w-12 text-indigo-600" />
          </div>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow-2xl sm:rounded-2xl sm:px-10 border border-gray-300 relative overflow-hidden">
            <div className="absolute top-0 left-0 right-0 h-1.5 bg-gradient-to-r from-indigo-600 via-purple-600 to-pink-600" />

            <div className="sm:mx-auto sm:w-full sm:max-w-md">
              <h2 className="text-center text-3xl font-semibold tracking-tight text-gray-900">
                Restablecer contraseña
              </h2>
              <p className="mt-3 text-center text-sm text-gray-600">Ingresa tu nueva contraseña</p>
            </div>

            <form onSubmit={handleSubmit} className="mt-8 space-y-6">
              <div className="relative w-full">
                <label className="block text-sm font-medium text-gray-700" htmlFor="password">
                  Nueva contraseña
                </label>
                <div className="mt-1.5 relative">
                  <input
                    id="password"
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="block w-full appearance-none rounded-xl border-2 border-gray-200 bg-white px-4 py-3.5 placeholder-gray-400 shadow-sm transition duration-200 focus:border-indigo-500 focus:outline-none focus:ring-4 focus:ring-indigo-500/20 hover:border-gray-300"
                    required
                    minLength={8}
                  />
                  <button
                    type="button"
                    onClick={() => setShowPassword(!showPassword)}
                    className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400 hover:text-gray-600"
                  >
                    {showPassword ? <EyeOff className="h-5 w-5" /> : <Eye className="h-5 w-5" />}
                  </button>
                </div>
              </div>

              <div className="relative w-full">
                <label className="block text-sm font-medium text-gray-700" htmlFor="confirm-password">
                  Confirmar contraseña
                </label>
                <div className="mt-1.5 relative">
                  <input
                    id="confirm-password"
                    type={showPassword ? "text" : "password"}
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    className="block w-full appearance-none rounded-xl border-2 border-gray-200 bg-white px-4 py-3.5 placeholder-gray-400 shadow-sm transition duration-200 focus:border-indigo-500 focus:outline-none focus:ring-4 focus:ring-indigo-500/20 hover:border-gray-300"
                    required
                    minLength={8}
                  />
                </div>
              </div>

              {error && (
                <div className="rounded-xl bg-red-100 p-4 border border-red-200">
                  <p className="text-sm text-red-700 font-medium">{error}</p>
                </div>
              )}

              <button
                type="submit"
                className="flex w-full justify-center items-center rounded-lg bg-indigo-600 px-4 py-4 text-base font-semibold text-white shadow-md transition duration-200 hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-70 disabled:cursor-not-allowed"
                disabled={processing}
              >
                {processing ? (
                  <>
                    <Loader className="mr-2 h-4 w-4 animate-spin" />
                    <span>Procesando...</span>
                  </>
                ) : (
                  <span>Cambiar contraseña</span>
                )}
              </button>
            </form>

            <div className="mt-6 text-center">
              <Link
                to="/auth/login"
                className="inline-flex items-center text-sm font-medium text-indigo-600 hover:text-indigo-500"
              >
                <ArrowLeft className="mr-2 h-4 w-4" />
                Volver al inicio de sesión
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
