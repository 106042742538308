import request from "../common/Request"

const TalentyApi = process.env.REACT_APP_BACKEND_API

const ENDPOINTS = {
  login: "/login",
  register: "/register",
  logout: "/logout",
  "forgot-password": "/forgot-password",
  "reset-password": "/reset-password",
  "change-password": "/change-password",
}

const Config = {
  headers: {
    accept: "application/json",
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
  },
}

export const login = async (payload) => {
  return request("POST", `${TalentyApi}${ENDPOINTS.login}`, payload, Config)
}

export const register = async (payload) => {
  return request("POST", `${TalentyApi}${ENDPOINTS.register}`, payload, Config)
}

export const logout = async () => {
  return request("POST", `${TalentyApi}${ENDPOINTS.logout}`, Config)
}

export const sendEmailToResetPassword = async (payload) => {
  return request("POST", `${TalentyApi}${ENDPOINTS["forgot-password"]}`, payload, Config)
}

export const resetPassword = async (payload) => {
  return request("POST", `${TalentyApi}${ENDPOINTS["reset-password"]}`, payload, Config)
}

export const changePassword = async (payload) => {
  return request("POST", `${TalentyApi}${ENDPOINTS["change-password"]}`, payload, Config)
}
