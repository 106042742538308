import React, { useContext, useEffect, useState } from "react"
//import EmailSent from "modules/Auth/components/EmailSent"
import { AuthContext } from "contexts/AuthContext"
import Loader from "../../components/Loaders/Loader"
import EmailSent from "../../modules/Auth/components/EmailSent"
import { ArrowLeft, Eye, EyeOff } from "lucide-react"
import { Link } from "react-router-dom"
//import RegisterService from "modules/Auth/services/RegisterService";

export default function Register() {
  const [emailSent, setEmailSent] = useState(null)
  const [processing, setProcessing] = useState(false)
  // const [error, setError] = useState("test error")
  const { signUp } = useContext(AuthContext)
  const [errorMessages, setErrorMessages] = useState({})

  const [showPassword, setShowPassword] = useState(false)

  const [showAlert, setShowAlert] = useState(true)

  useEffect(() => {
    if (errorMessages && Object.keys(errorMessages).length > 0) {
      const timer = setTimeout(() => {
        setShowAlert(false)
      }, 5000) // Hide after 5 seconds
      return () => clearTimeout(timer)
    }
  }, [errorMessages])
  const closeAlert = () => {
    setShowAlert(false)
  }

  const handleSubmit = async (event) => {
    try {
      event.preventDefault()
      setProcessing(true)

      const formData = new FormData(event.currentTarget)
      const payload = {
        name: formData.get("name"),
        email: formData.get("email"),
        password: formData.get("password"),
      }

      const response = await signUp(payload)
      const { success } = response

      if (success) {
        setEmailSent(true)
      }
    } catch (error) {
      setProcessing(false)
      setErrorMessages(error.errors)
      setShowAlert(true)
    }
  }

  return (
    <>
      {!emailSent ? (
        <div className="min-h-screen bg-gradient-to-br from-white via-white to-purple-400">
          <div className="flex min-h-screen flex-col justify-center px-4 py-12 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
              <img className="mx-auto h-20 w-auto" src={require("assets/img/talenty_logo_200x110.png")} alt="Talenty" />
            </div>
            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
              <div className="bg-white py-8 px-4 shadow-2xl sm:rounded-2xl sm:px-10 border border-gray-300 relative overflow-hidden">
                <div className="absolute top-0 left-0 right-0 h-1.5 bg-gradient-to-r from-indigo-600 via-purple-600 to-pink-600 animate-gradient" />
                <div className="sm:mx-auto sm:w-full sm:max-w-md">
                  <h2 className="text-center text-3xl font-semibold tracking-tight text-gray-900">Crear cuenta</h2>
                  <p className="mt-3 text-center text-sm text-gray-600">Ingresa sus datos para continuar</p>
                </div>
                <form onSubmit={handleSubmit} className="mt-8 space-y-6">
                  <div className="relative w-full mb-3">
                    <label className="block text-sm font-medium text-gray-700" htmlFor="name">
                      Nombre
                    </label>
                    <div className="mt-1.5 relative">
                      <input
                        id="name"
                        type="text"
                        name="name"
                        placeholder="nombre"
                        className="block w-full appearance-none rounded-xl border-2 border-gray-200 bg-white px-4 py-3.5 placeholder-gray-400 shadow-sm transition duration-200 focus:border-indigo-500 focus:outline-none focus:ring-4 focus:ring-indigo-500/20 hover:border-gray-300"
                        required
                      />
                    </div>
                  </div>

                  <div className="relative w-full mb-3">
                    <label className="block text-sm font-medium text-gray-700" htmlFor="email">
                      Email
                    </label>
                    <div className="mt-1.5 relative">
                      <input
                        id="email"
                        type="email"
                        name="email"
                        placeholder="email"
                        className="block w-full appearance-none rounded-xl border-2 border-gray-200 bg-white px-4 py-3.5 placeholder-gray-400 shadow-sm transition duration-200 focus:border-indigo-500 focus:outline-none focus:ring-4 focus:ring-indigo-500/20 hover:border-gray-300"
                        required
                      />
                    </div>
                  </div>

                  <div className="relative w-full mb-3">
                    <label className="block text-sm font-medium text-gray-700" htmlFor="password">
                      Password
                    </label>
                    <div className="mt-1.5 relative">
                      <input
                        id="password"
                        type={showPassword ? "text" : "password"}
                        name="password"
                        placeholder="••••••••"
                        className="block w-full appearance-none rounded-xl border-2 border-gray-200 bg-white px-4 py-3.5 placeholder-gray-400 shadow-sm transition duration-200 focus:border-indigo-500 focus:outline-none focus:ring-4 focus:ring-indigo-500/20 hover:border-gray-300"
                        required
                      />
                      <button
                        type="button"
                        onClick={() => setShowPassword(!showPassword)}
                        className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400 hover:text-gray-600"
                      >
                        {showPassword ? <EyeOff className="h-5 w-5" /> : <Eye className="h-5 w-5" />}
                      </button>
                    </div>
                  </div>

                  {showAlert && Object.keys(errorMessages).length > 0 && (
                    <div className="rounded-xl bg-red-100 p-4 border border-red-200">
                      <div className="flex items-center justify-between">
                        <div className="flex items-center">
                          <div className="flex-shrink-0">
                            <button onClick={closeAlert} className="text-red-500 hover:text-red-700">
                              <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                <path
                                  fillRule="evenodd"
                                  d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </button>
                          </div>
                          <div className="ml-3">
                            {" "}
                            {Object.keys(errorMessages).map((key) => (
                              <p className="text-sm text-red-700 font-medium" key={key}>
                                {" "}
                                {errorMessages[key].join(", ")}{" "}
                              </p>
                            ))}{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="text-center mt-6">
                    <button
                      type="submit"
                      className="flex w-full justify-center items-center rounded-lg bg-indigo-600 px-4 py-4 text-base font-semibold text-white shadow-md transition duration-200 hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-70 disabled:cursor-not-allowed hover:shadow-lg"
                      disabled={processing}
                    >
                      {processing ? (
                        <>
                          <Loader className="mr-2 h-4 w-4 animate-spin" />
                          <span>Procesando...</span>
                        </>
                      ) : (
                        <span className="group-hover:scale-105 transition-transform duration-200">Crear cuenta</span>
                      )}
                    </button>
                  </div>
                </form>
                <div className="mt-6 text-center">
                  <Link
                    to="/auth/login"
                    className="inline-flex items-center text-sm font-medium text-indigo-600 hover:text-indigo-500"
                  >
                    <ArrowLeft className="mr-2 h-4 w-4" />
                    Volver al inicio de sesión
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <EmailSent />
      )}
    </>
  )
}
