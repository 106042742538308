import { useLocalStorage } from "./useLocalStorage"
import { useState } from "react"
import { login, logout, register } from "../modules/Auth/AuthServices"

export const useAuth = () => {
  const [storedToken, setStoredToken] = useLocalStorage("talenty-token", null)
  const [storedUser, setStoredUser] = useLocalStorage("talenty-user", null)
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [user, setUser] = useState(null)

  const signIn = async (payload) => {
    try {
      const response = await login(payload)
      const { success, message, data, status, statusText } = response
      const { token } = data

      if (success) {
        updateAuthState(true, data, token)
      }

      return { success, message, data, status, statusText }
    } catch (error) {
      const { success, message, errors, status, statusText } = error
      throw { success, message, errors, status, statusText }
    }
  }

  const signUp = async (payload) => {
    try {
      const response = await register(payload)
      const { success, message, data, status, statusText } = response
      return { success, message, data, status, statusText }
    } catch (error) {
      const { success, message, errors, status, statusText } = error
      throw { success, message, errors, status, statusText }
    }
  }

  const signOut = async () => {
    try {
      const response = await logout()
      const { success, message, data, status, statusText } = response

      if (success) {
        updateAuthState(false, null, null)
      }

      return { success, message, data, status, statusText }
    } catch (error) {
      const { success, message, errors, status, statusText } = error
      throw { success, message, errors, status, statusText }
    }
  }

  const updateAuthState = (isAuthenticated, user = data, token) => {
    setIsAuthenticated(isAuthenticated)
    setUser(user)
    setStoredToken(token)
    setStoredUser(user)
  }

  return { signIn, signUp, signOut, isAuthenticated, user, setIsAuthenticated, setUser, storedUser, storedToken }
}
